
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');


/* Styles from the GreenSock website */
body {
    font-weight: 300;
}

.ipsType_right {
    text-align: right;
}

.ipsType_center {
    text-align: center;
}

.cInnerContent {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

.ipsSpacer_bottom_double {
    margin-bottom: 30px;
}

.ipsGrid {
    display: inline-block;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.ipsGrid::before,
.ipsGrid::after {
    display: table;
    content: "";
    line-height: 0;
}

.ipsGrid>[class*="ipsGrid_span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    box-sizing: border-box;
}

.ipsGrid>.ipsGrid_span5 {
    width: 40.42553191489362%;
}

.ipsGrid>.ipsGrid_span7 {
    width: 57.44680851063829%;
}

.ipsGrid>[class*="ipsGrid_span"] {
    float: left;
    margin-left: 2%;
}

.ipsGrid>[class*="ipsGrid_span"]:first-child {
    margin-left: 0;
}

.feature {
    display: flex;
    align-items: center;
}

.card {
    margin-bottom: 30px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 1px 1px 5px 1px #CCCCCC;
    /*   transition: 0.3s; */
}

.featured-image-container .card {
    padding: 10px;
    height: 0;
    /* padding-bottom: calc(100% - 10px); */
    padding: 10px;
    height: 356px;
}

img {
    max-width: 100%;
}

.header-section {
    margin: 200px auto;
}

.gs_reveal {
    opacity: 0;
    visibility: hidden;
    will-change: transform, opacity;
}


.logo {
    position: fixed;
    top: 7px;
    z-index: 10;
    width: 5rem;
    height: auto;
}

h1,
h2 {
    font-family: 'Playfair Display', serif;
}

h1 {
    font-size: 65px;
}

h2 {
    font-size: 45px;
    line-height: 1.1em;
    letter-spacing: -1px;
}

.features {
    margin-top: 6rem;
}

.feature p {
    font-family: sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    padding-left: 17rem;
    /* font-size: 14px;
    line-height: 24px;
    padding-left: 25rem; */
}

.feature:nth-child(odd) p  {
    padding-left: 0rem;
    padding-right: 17rem;
}

.feature button {
    border: 1px solid #f37832;
    background-color: #fff;
    color: #f37832;
    text-transform: uppercase;
    padding: 0.65rem 1rem;
}
